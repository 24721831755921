import React from "react"


import Layout from "../components/layout"
import "./About.css"
import Button from "../components/Button";

const AboutPage = () => (
  <Layout>
    <div className="AboutPage">
      <div className="SelfIntro">
        <p>Hi there! I'm a UX designer at Microsoft. Recently my work has been focusing on data privacy and human-AI collaboration.</p>
        <p>Fun facts about me :)<br />
        >>> I came from an Architecture background, specified in ecological design and data-driven landscape planning 🌲<br />
        >>> I’m a huge sci-fi fan. My current favorite is <i>Lord of Light</i> by Roger Zelazny 🐒</p>
      <Button 
        link="/"
        text="← Work"
      />
      <Button 
        link="https://drive.google.com/file/d/1drwGt1nQprPtGIEZ2WDFaUHPrTKMMPBw/view?usp=sharing"
        text="Resume"
      />
      </div>
    <img src={require("../images/profile-photo.jpg")} width="480px" />

  {/* a */}

    </div>
  </Layout>
)

export default AboutPage;
